import { observable, flow } from 'mobx'
import api from '../api'
import { makeThumbnail, makeOriginal } from '~/src/lib/imageCompressor'

class UserStore {
    @observable isSubmitting = false
    @observable error = null
    @observable users = []
    @observable count = 0
    @observable userCredits = []
    @observable userCreditCount = 0
    
    createStaff = flow(function* (token, shop, username, email, password, status) {
        this.isSubmitting = true
        try {
            yield api.createStaff(token, shop, username, email, password, status)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    listStaff = flow(function* (token, shop, limit, skip, sort, username, email, status, createdAtMin, createdAtMax) {
        this.isSubmitting = true
        try {
            const response = yield api.listStaff(token, shop, limit, skip, sort, username, email, status, createdAtMin, createdAtMax)
            const { users, count } = response.data
            this.users = users
            this.count = count
            this.error = null
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    updateStaff = flow(function* (token, shop, id, status) {
        this.isSubmitting = true
        try {
            const response = yield api.updateStaff(token, shop, id, status)
            const index = this.users.findIndex((b) => {
                return b._id===id
            })
            this.users[index] = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    listMember = flow(function* (token, shop, limit, skip, sort, username, email, status, validationDateMin, validationDateMax) {
        this.isSubmitting = true
        try {
            const response = yield api.listMember(token, shop, limit, skip, sort, username, email, status, validationDateMin, validationDateMax)
            const { users, count } = response.data
            this.users = users
            this.count = count
            this.error = null
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    listMemberCredit = flow(function* (token, shop, limit, skip, sort, username, email) {
        this.isSubmitting = true
        try {
            const response = yield api.listMemberCredit(token, shop, limit, skip, sort, username, email)
            const { userCredits, count } = response.data
            this.userCredits = userCredits
            this.userCreditCount = count
            this.error = null
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })
}

export default new UserStore()
