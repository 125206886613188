import axios from 'axios'

const BASE_URL = process.env.REACT_APP_BASE_URL
const POSPAL_URL = process.env.REACT_APP_POSPAL_URL

async function login(email, password) {
    const options = {
        method: 'post',
        url: `${BASE_URL}/user/login`,
        data: {
            email,
            password
        }
    }
    return axios(options)
}

async function forgotPassword(email) {
    const options = {
        method: 'post',
        url: `${BASE_URL}/user/forgotPassword`,
        data: {
            email
        }
    }
    return axios(options)
}

async function resetPassword(token, password) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/user/resetPassword`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            password
        }
    }
    return axios(options)
}

async function createCategory(token, shop, parent, name, status, weekdays, priority) {
    const options = {
        method: 'post',
        url: `${BASE_URL}/shop/${shop}/category`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            parent,
            name,
            status,
            weekdays,
            priority
        }
    }
    return axios(options)
}

async function listCategory(token, shop) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/category`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function getCategory(token, shop, id) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/category/${id}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function updateCategory(token, shop, id, name, status, weekdays, priority) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/shop/${shop}/category/${id}`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            name,
            status,
            weekdays,
            priority
        }
    }
    return axios(options)
}

async function deleteCategory(token, shop, id) {
    const options = {
        method: 'delete',
        url: `${BASE_URL}/shop/${shop}/category/${id}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function createProduct(token, shop, formData) {
    const options = {
        method: 'post',
        url: `${BASE_URL}/shop/${shop}/product`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: formData
    }
    return axios(options)
}

async function getProduct(token, shop, id) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/product/${id}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function listProduct(token, shop, limit, skip, shopType,
    sort,
    search,
    productId,
    name,
    isRecommended,
    group,
    category,
    productType,
    status,
    createdAtMin,
    createdAtMax
) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/product`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            limit,
            skip,
            shopType,
            sort,
            search,
            productId,
            name,
            isRecommended,
            group,
            category,
            productType,
            status,
            createdAtMin,
            createdAtMax
        }
    }
    return axios(options)
}

async function updateProduct(token, shop, id, formData) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/shop/${shop}/product/${id}`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: formData
    }
    return axios(options)
}

async function deleteProduct(token, shop, id) {
    const options = {
        method: 'delete',
        url: `${BASE_URL}/shop/${shop}/product/${id}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function createOption(token, shop, formData) {
    const options = {
        method: 'post',
        url: `${BASE_URL}/shop/${shop}/productOption`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: formData
    }
    return axios(options)
}

async function createFoodOption(token, shop, fieldName, selectionRequired, remark, priority, choices) {
    const options = {
        method: 'post',
        url: `${BASE_URL}/shop/${shop}/foodOption`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            fieldName, selectionRequired, remark, priority, choices
        }
    }
    return axios(options)
}

async function updateFoodOption(token, shop, id, fieldName, selectionRequired, remark, priority, choices) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/shop/${shop}/foodOption/${id}`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            fieldName, selectionRequired, remark, priority, choices
        }
    }
    return axios(options)
}

async function updateProductOption(token, shop, id, optionIds) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/shop/${shop}/product/${id}/productOption`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            optionIds
        }
    }
    return axios(options)
}

async function updateProductFoodOption(token, shop, id, optionIds) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/shop/${shop}/product/${id}/foodOption`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            optionIds
        }
    }
    return axios(options)
}

async function updateOption(token, shop, id, formData) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/shop/${shop}/productOption/${id}`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: formData
    }
    return axios(options)
}

async function deleteOption(token, shop, id) {
    const options = {
        method: 'delete',
        url: `${BASE_URL}/shop/${shop}/productOption/${id}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function deleteFoodOption(token, shop, id) {
    const options = {
        method: 'delete',
        url: `${BASE_URL}/shop/${shop}/foodOption/${id}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function listOption(token, shop, limit, skip, sort, name, status, createdAtMin, createdAtMax) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/productOption`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            limit, skip, sort,
            name, status, createdAtMin, createdAtMax
        }
    }
    return axios(options)
}

async function listFoodOption(token, shop, limit, skip, sort, name, status, createdAtMin, createdAtMax) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/foodOption`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            limit, skip, sort,
            name, status, createdAtMin, createdAtMax
        }
    }
    return axios(options)
}

async function listProductFoodOption(token, shop, id) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/product/${id}/foodOption`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function listProductOption(token, shop, id) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/product/${id}/productOption`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function listProductStock(token, shop, id, limit, skip, sort) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/product/${id}/stock`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            limit, skip, sort
        }
    }
    return axios(options)
}

async function updateProductStock(token, shop, id, stocks) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/shop/${shop}/product/${id}/stock`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            stocks
        }
    }
    return axios(options)
}

async function createReservation(token,
    shop,
    product,
    userCountMin,
    userCountMax,
    time,
    duration,
    options,
    status
) {
    const _options = {
        method: 'post',
        url: `${BASE_URL}/shop/${shop}/product/${product}/reservation`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            userCountMin,
            userCountMax,
            time,
            duration,
            options,
            status
        }
    }
    return axios(_options)
}


async function createReservationGroup(token,
    shop,
    product,
    userCountMin,
    userCountMax,
    startDate,
    endDate,
    week,
    timeSlots,
    duration,
    options,
    status
) {
    const _options = {
        method: 'post',
        url: `${BASE_URL}/shop/${shop}/product/${product}/reservation/group`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            userCountMin,
            userCountMax,
            startDate,
            endDate,
            week,
            timeSlots,
            duration,
            options,
            status
        }
    }
    return axios(_options)
}

async function listReservation(token, shop, product, limit, skip,
    sort,
    timeMin,
    timeMax,
    status
) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/product/${product}/reservation`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            limit,
            skip,
            sort,
            timeMin,
            timeMax,
            status
        }
    }
    return axios(options)
}

async function updateReservation(token, shop, product, id,
    userCountMin,
    userCountMax,
    time,
    duration,
    options,
    status
) {
    const _options = {
        method: 'put',
        url: `${BASE_URL}/shop/${shop}/product/${product}/reservation/${id}`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            userCountMin,
            userCountMax,
            time,
            duration,
            options,
            status
        }
    }
    return axios(_options)
}

async function deleteReservation(token, shop, product, id) {
    const _options = {
        method: 'delete',
        url: `${BASE_URL}/shop/${shop}/product/${product}/reservation/${id}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(_options)
}

async function createAdsBanner(token, shop, formData) {
    const options = {
        method: 'post',
        url: `${BASE_URL}/shop/${shop}/adBanner`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: formData
    }
    return axios(options)
}

async function listAdsBanner(token, shop, limit, skip, sort, name, status, createdAtMin, createdAtMax) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/adBanner`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            limit,
            skip,
            sort,
            name,
            status,
            createdAtMin,
            createdAtMax
        }
    }
    return axios(options)
}

async function updateAdsBanner(token, shop, id, formData) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/shop/${shop}/adBanner/${id}`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: formData
    }
    return axios(options)
}

async function deleteAdsBanner(token, shop, id) {
    const options = {
        method: 'delete',
        url: `${BASE_URL}/shop/${shop}/adBanner/${id}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function createFeed(token, shop, formData) {
    const options = {
        method: 'post',
        url: `${BASE_URL}/shop/${shop}/feed`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: formData
    }
    return axios(options)
}

async function listFeed(token, shop, limit, skip, sort, title, status) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/feed`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            limit,
            skip,
            sort,
            title,
            status
        }
    }
    return axios(options)
}

async function updateFeed(token, shop, id, formData) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/shop/${shop}/feed/${id}`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: formData
    }
    return axios(options)
}

async function deleteFeed(token, shop, feedId) {
    const options = {
        method: 'delete',
        url: `${BASE_URL}/shop/${shop}/feed/${feedId}`,
        headers: {
            'Authorization': `JWT ${token}`
        },
    }
    return axios(options)
}
 
async function getSelf(token) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/user/self`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}


async function createShopCoupon(token, shop, formData) {
    const options = {
        method: 'post',
        url: `${BASE_URL}/shop/${shop}/coupon`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: formData
    }
    return axios(options)
}


async function createProductCoupon(token, shop, product, formData) {
    const options = {
        method: 'post',
        url: `${BASE_URL}/shop/${shop}/product/${product}/coupon`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: formData
    }
    return axios(options)
}

async function createManyCoupon(token, shop, formData) {
    const options = {
        method: 'post',
        url: `${BASE_URL}/shop/${shop}/coupon/many`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: formData
    }
    return axios(options)
}

async function listShopCoupon(token, shop, limit, skip,
    sort,
    couponType,
    name,
    startDateMin,
    startDateMax,
    endDateMin,
    endDateMax,
    createdAtMin,
    createdAtMax,
    status
) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/coupon`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            limit,
            skip,
            sort,
            name,
            couponType,
            startDateMin,
            startDateMax,
            endDateMin,
            endDateMax,
            createdAtMin,
            createdAtMax,
            status
        }
    }
    return axios(options)
}

async function listProductCoupon(token, shop, product, limit, skip,
    sort,
    couponType,
    name,
    stateDateMin,
    stateDateMax,
    endDateMin,
    endDateMax,
    createdAtMin,
    createdAtMax,
    status
) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/product/${product}/coupon`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            limit,
            skip,
            sort,
            couponType,
            name,
            stateDateMin,
            stateDateMax,
            endDateMin,
            endDateMax,
            createdAtMin,
            createdAtMax,
            status
        }
    }
    return axios(options)
}

async function updateCoupon(token, shop, id, formData) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/shop/${shop}/coupon/${id}`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: formData
    }
    return axios(options)
}

async function getSelfShop(token) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/self`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function updateSelfShop(token, formData) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/shop/self`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: formData
    }
    return axios(options)

}

async function listOrder(token, shop, limit, skip,
    sort,
    orderId,
    type,
    status,
    trackingNumber,
    createdAtMin,
    createdAtMax
) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/order`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            limit,
            skip,
            sort,
            orderId,
            type,
            status,
            trackingNumber,
            createdAtMin,
            createdAtMax
        }
    }
    return axios(options)
}

async function getOrder(token, shop, id) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/order/${id}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function updateOrder(token, shop, id, status, trackingNumber) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/shop/${shop}/order/${id}`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            status, trackingNumber
        }
    }
    return axios(options)
}

async function createStaff(token, shop, username, email, password, status) {
    const options = {
        method: 'post',
        url: `${BASE_URL}/shop/${shop}/staff`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            username,
            email,
            password,
            status
        }
    }
    return axios(options)
}

async function listStaff(token, shop, limit, skip, sort, username, email, status, createdAtMin, createdAtMax) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/staff`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            limit,
            skip,
            sort,
            username,
            email,
            status,
            createdAtMin,
            createdAtMax
        }
    }
    return axios(options)
}

async function updateStaff(token, shop, id, status) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/shop/${shop}/staff/${id}`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            status
        }
    }
    return axios(options)
}

async function listMember(token, shop, limit, skip, sort, username, email, status, validationDateMin, validationDateMax) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/member`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            limit,
            skip,
            sort,
            username,
            email,
            status,
            validationDateMin,
            validationDateMax
        }
    }
    return axios(options)
}

async function listPospalTicketInRealTime(token, id, startTime, endTime) {
    const options = {
        method: 'post',
        url: `${POSPAL_URL}/pospal/${id}/queryTicketPages`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            startTime,
            endTime
        }
    }
    return axios(options)
}

async function listPospalTicket(token, shop, id, limit, skip, sort, startTime, endTime) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/pospal/${id}/ticket`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            limit,
            skip,
            sort,
            startTime,
            endTime
        }
    }
    return axios(options)
}

async function listPospalBranch(token, shop) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/pospal`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function listPospalCategory(token, id) {
    const options = {
        method: 'get',
        url: `${POSPAL_URL}/pospal/${id}/getCategories`,
        headers: {
            'Authorization': `JWT ${token}`
        },
    }
    return axios(options)

}

async function createPospalCategory(token, id, parentCategoryName, categoryName) {
    const options = {
        method: 'post',
        url: `${POSPAL_URL}/pospal/${id}/createCategory`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            parentCategoryName,
            categoryName
        }
    }
    return axios(options)
}

async function updatePospalCategory(token, id, categoryUid, parentCategoryName, newCategoryName) {
    const options = {
        method: 'post',
        url: `${POSPAL_URL}/pospal/${id}/updateCategory`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            categoryUid,
            parentCategoryName,
            newCategoryName
        }
    }
    return axios(options)
}

async function deletePospalCategory(token, id, categoryUidsJson) {
    const options = {
        method: 'post',
        url: `${POSPAL_URL}/pospal/${id}/deleteCategory`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            categoryUidsJson
        }
    }
    return axios(options)
}

async function listPospalOptionGroup(token, id) {
    const options = {
        method: 'get',
        url: `${POSPAL_URL}/pospal/${id}/loadStoreProductTasteGroups`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function savePospalOptionGroup(token, id, uid, packageName, enjoyDiscount, attributes) {
    const options = {
        method: 'post',
        url: `${POSPAL_URL}/pospal/${id}/saveProductAttributePackage`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            uid, packageName, enjoyDiscount, attributes
        }
    }
    return axios(options)

}

async function deletePospalOptionGroup(token, id, packageUid) {
    const options = {
        method: 'post',
        url: `${POSPAL_URL}/pospal/${id}/deleteProductAttributePackage`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            packageUid
        }
    }
    return axios(options)
}

async function listPospalProduct(token, id, pageIndex, pageSize) {
    const options = {
        method: 'get',
        url: `${POSPAL_URL}/pospal/${id}/loadProducts`,
        headers: {
            'Authorization': `JWT ${token}`,
            'Content-Type': 'application/json'
        },
        params: {
            pageIndex, pageSize
        }
    }
    return axios(options)
}

async function getPospalProduct(token, id, productId) {
    const options = {
        method: 'post',
        url: `${POSPAL_URL}/pospal/${id}/findProduct`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            productId
        }
    }
    return axios(options)
}

async function createPospalProduct(token, id, name, barcode, categoryUid, categoryName, sellPrice, buyPrice, isCustomerDiscount, customerPrice, sellPrice2, stock, productTastes) {
    const options = {
        method: 'post',
        url: `${POSPAL_URL}/pospal/${id}/createProduct`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            name, barcode, categoryUid, categoryName, sellPrice, buyPrice, isCustomerDiscount, customerPrice, sellPrice2, stock, productTastes
        }
    }
    return axios(options)
}

async function editPospalProduct(token, id, productId, name, barcode, categoryUid, categoryName, sellPrice, buyPrice, isCustomerDiscount, customerPrice, sellPrice2, stock, productTastes) {
    const options = {
        method: 'post',
        url: `${POSPAL_URL}/pospal/${id}/editProduct`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            productId, name, barcode, categoryUid, categoryName, sellPrice, buyPrice, isCustomerDiscount, customerPrice, sellPrice2, stock, productTastes
        }
    }
    return axios(options)
}

async function deletePospalProduct(token, id, productId) {
    const options = {
        method: 'post',
        url: `${POSPAL_URL}/pospal/${id}/deleteProduct`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            productId
        }
    }
    return axios(options)
}

async function importPospalProduct(token, id, products) {
    const requests = products.map(p => {
        const { name, barcode, categoryUid, categoryName, sellPrice, buyPrice, isCustomerDiscount, customerPrice, sellPrice2, stock, productTastes } = p
        const options = {
            method: 'post',
            url: `${POSPAL_URL}/pospal/${id}/createProduct`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: {
                name, barcode, categoryUid, categoryName, sellPrice, buyPrice, isCustomerDiscount, customerPrice, sellPrice2, stock, productTastes
            }
        }
        return axios(options)
    })
    return axios.all(requests)
}

async function listPospalMember(token, shop, id, limit, skip, sort, name, phone, email) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/pospal/${id}/member`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            limit,
            skip,
            sort,
            name,
            phone,
            email
        }
    }
    return axios(options)
}

async function createPospalMember(token, shop, id, categoryName, number, name, point, discount, balance, phone, email) {
    const options = {
        method: 'post',
        url: `${BASE_URL}/shop/${shop}/pospal/${id}/member`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            categoryName, number, name, point, discount, balance, phone, email
        }
    }
    return axios(options)
}

async function updatePospalMember(token, shop, id, memberId, customerUid, categoryName, name, discount, phone, email, enable, shopperUserId) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/shop/${shop}/pospal/${id}/member/${memberId}`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            customerUid, categoryName, name, discount, phone, email, enable, shopperUserId
        }
    }
    return axios(options)
}

async function importPospalMember(token, shop, id, members) {
    const requests = members.map(member => {
        const { categoryName, number, name, point, discount, balance, phone, email } = member
        const options = {
            method: 'post',
            url: `${BASE_URL}/shop/${shop}/pospal/${id}/member`,
            headers: {
                'Authorization': `JWT ${token}`
            },
            data: {
                categoryName, number, name, point, discount, balance, phone, email
            }
        }
        return axios(options)
    })
    return axios.all(requests)
}

async function emailPospalTicket(token, shop, id, ticketSn, email) {
    const options = {
        method: 'post',
        url: `${BASE_URL}/shop/${shop}/pospal/${id}/ticket/${ticketSn}/email`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            email
        }
    }
    return axios(options)
}

async function getPospalUserCategory(token, shop, id) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/pospal/${id}/member/customerCategory`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function linkPospalProduct(token, shop, id, productId, pospalProductId) {
    const options = {
        method: 'post',
        url: `${BASE_URL}/shop/${shop}/pospal/${id}/link`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            productId,
            pospalProductId
        }
    }
    return axios(options)
}

async function unlinkPospalProduct(token, shop, id, linkId) {
    const options = {
        method: 'delete',
        url: `${BASE_URL}/shop/${shop}/pospal/${id}/link/${linkId}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function updateStockTake(token, branch, selectedProductId, shopperProductId, pospalProductData, shopperProductData) {
    const { name, barcode, categoryUid, categoryName, sellPrice, buyPrice, isCustomerDiscount, customerPrice, sellPrice2, stock, productTastes } = pospalProductData
    const pospalProductRequest = {
        method: 'post',
        url: `${POSPAL_URL}/pospal/${branch}/editProduct`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            productId: selectedProductId,
            name, barcode, categoryUid, categoryName, sellPrice, buyPrice, isCustomerDiscount, customerPrice, sellPrice2, stock, productTastes
        }
    }
    const productRequest = {
        method: 'put',
        url: `${BASE_URL}/product/${shopperProductId}/stock/single`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            stock: shopperProductData.stock
        }
    }
    return axios.all([axios(pospalProductRequest), axios(productRequest)])
}

async function inactivateReservations(token, shop, product, reservationIds) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/shop/${shop}/product/${product}/reservation/groupInactivate`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            reservationIds
        }
    }
    return axios(options)
}

async function getMemberCount(token, shop) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/analysis/memberCount`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function getMemberCountByPeriod(token, shop, mode, startDate, endDate) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/analysis/memberCountByPeriod`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            mode, startDate, endDate
        }
    }
    return axios(options)
}

async function getMemberCumulativeCountByPeriod(token, shop, mode, startDate, endDate) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/analysis/memberCumulativeCountByPeriod`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            mode, startDate, endDate
        }
    }
    return axios(options)
}

async function getOrderSummary(token, shop, startDate, endDate) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/analysis/orderSummaryByRange`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            startDate,
            endDate
        }
    }
    return axios(options)
}

async function getFoodOrderSummary(token, shop, startDate, endDate, deliveryMethod) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/analysis/foodOrderSummaryByRange`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            startDate,
            endDate,
            deliveryMethod
        }
    }
    return axios(options)
}

async function getOrderSummaryByPeriod(token, shop, mode, startDate, endDate) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/analysis/orderSummaryByPeriod`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            mode, startDate, endDate
        }
    }
    return axios(options)
}

async function getFoodOrderSummaryByPeriod(token, shop, mode, startDate, endDate, deliveryMethod) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/analysis/foodOrderSummaryByPeriod`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            mode, startDate, endDate, deliveryMethod
        }
    }
    return axios(options)
}

async function listMostBookmarkedProducts(token, shop) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/analysis/mostBookmarkedProducts`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function listMostOrderMembers(token, shop) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/analysis/mostOrderMembers`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function listMostFoodOrderMembers(token, shop) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/analysis/mostFoodOrderMembers`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function listMostAmountMembers(token, shop) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/analysis/mostAmountMembers`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function listMostFoodAmountMembers(token, shop) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/analysis/mostFoodAmountMembers`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function importProduct(token, shop, formData) {
    const options = {
        method: 'post',
        url: `${BASE_URL}/shop/${shop}/product/import`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: formData
    }
    return axios(options)
}

async function getShop(shopId) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shopId}`,
    }
    return axios(options)
}

async function getShopByInitial(shopInitial) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/initial/${shopInitial}`
    }
    return axios(options)
}

async function getShopByDomainName(domainName) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/domainName`,
        params: {
            domainName
        }
    }
    return axios(options)
}

async function createNotification(token, shop, title, body) {
    const options = {
        method: 'post',
        url: `${BASE_URL}/shop/${shop}/notification`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            title, body
        }
    }
    return axios(options)
}

async function listFoodOrder(token, shop, limit, skip,
    sort,
    orderId,
    deliveryMethod,
    status,
    createdAtMin,
    createdAtMax
) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/foodOrder`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            limit,
            skip,
            sort,
            orderId,
            deliveryMethod,
            status,
            createdAtMin,
            createdAtMax
        }
    }
    return axios(options)
}

async function getFoodOrder(token, shop, id) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/foodOrder/${id}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function updateFoodOrder(token, shop, id, status) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/shop/${shop}/foodOrder/${id}`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            status
        }
    }
    return axios(options)
}

async function uploadPhoto(token, formData) {
    const options = {
        method: 'post',
        url: `${BASE_URL}/photo`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data:formData
    }
    return axios(options)
}

async function updateManyProductCategory(token, shop, category, productIds) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/shop/${shop}/category/${category}/group`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            productIds
        }
    }
    return axios(options)
}

async function createDeliveryTimeSlot(token, shop, name, startTime, endTime) {
    const options = {
        method: 'post',
        url: `${BASE_URL}/shop/${shop}/deliveryTimeSlot`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            name, startTime, endTime
        }
    }
    return axios(options)
}

async function updateDeliveryTimeSlot(token, shop, id, name, startTime, endTime) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/shop/${shop}/deliveryTimeSlot/${id}`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            name, startTime, endTime
        }
    }
    return axios(options)
}

async function deleteDeliveryTimeSlot(token, shop, id) {
    const options = {
        method: 'delete',
        url: `${BASE_URL}/shop/${shop}/deliveryTimeSlot/${id}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function listDeliveryTimeSlot(token, shop) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/deliveryTimeSlot`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function createFoodSubchoice(token, shop, fieldName, remark, priority, choices) {
    const options = {
        method: 'post',
        url: `${BASE_URL}/shop/${shop}/foodSubchoice`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            fieldName, remark, priority, choices
        }
    }
    return axios(options)
}

async function updateFoodSubchoice(token, shop, id, fieldName, remark, priority, choices) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/shop/${shop}/foodSubchoice/${id}`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            fieldName, remark, priority, choices
        }
    }
    return axios(options)
}

async function deleteFoodSubchoice(token, shop, id) {
    const options = {
        method: 'delete',
        url: `${BASE_URL}/shop/${shop}/foodSubchoice/${id}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function listFoodSubchoice(token, shop, limit, skip) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/foodSubchoice`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            limit, skip
        }
    }
    return axios(options)
}

async function createProductSubchoice(token, shop, fieldName, remark, priority, choices) {
    const options = {
        method: 'post',
        url: `${BASE_URL}/shop/${shop}/productSubchoice`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            fieldName, remark, priority, choices
        }
    }
    return axios(options)
}

async function updateProductSubchoice(token, shop, id, fieldName, remark, priority, choices) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/shop/${shop}/productSubchoice/${id}`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            fieldName, remark, priority, choices
        }
    }
    return axios(options)
}

async function deleteProductSubchoice(token, shop, id) {
    const options = {
        method: 'delete',
        url: `${BASE_URL}/shop/${shop}/productSubchoice/${id}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function listProductSubchoice(token, shop, limit, skip) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/productSubchoice`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            limit, skip
        }
    }
    return axios(options)
}

async function getMemberFoodBehaviorAnalysis(token, shop, startDate, endDate) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/analysis/memberFoodBehavior`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            startDate,
            endDate
        }
    }
    return axios(options)
}

async function getFoodSaleAnalysis(token, shop, startDate, endDate) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/analysis/foodSale`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            startDate,
            endDate
        }
    }
    return axios(options)
}

async function groupUpdateProductOption(token, shop, productIds, optionIds) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/shop/${shop}/product/productOption/group`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            productIds, optionIds
        }
    }
    return axios(options)
}

async function groupUpdateProductFoodOption(token, shop, productIds, optionIds) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/shop/${shop}/product/foodOption/group`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            productIds, optionIds
        }
    }
    return axios(options)
}

async function updateManyOrderStatus(token, shop, orderIds, status) {
    const options = {
        method: 'put',
        url: `${BASE_URL}/shop/${shop}/order/group/status`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        data: {
            orderIds, status
        }
    }
    return axios(options)
}

async function deleteCoupon(token, shop, id) {
    const options = {
        method: 'delete',
        url: `${BASE_URL}/shop/${shop}/coupon/${id}`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function searchProduct(token, shop, query) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/product/search`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            query
        }
    }
    return axios(options)
}

async function getProductStockHistory(token, shop, product) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/product/${product}/stock/history`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function listProductStockAlert(token, shop) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/product/stockAlert`,
        headers: {
            'Authorization': `JWT ${token}`
        }
    }
    return axios(options)
}

async function listMemberCredit(token, shop, limit, skip, sort, username, email) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/member/credit`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            limit,
            skip,
            sort,
            username,
            email
        }
    }
    return axios(options)
}

async function listScanPayment(token, shop, limit, skip) {
    const options = {
        method: 'get',
        url: `${BASE_URL}/shop/${shop}/scanPayment`,
        headers: {
            'Authorization': `JWT ${token}`
        },
        params: {
            limit,
            skip
        }
    }
    return axios(options)
}

export default {
    login,
    forgotPassword,
    resetPassword,
    createCategory,
    listCategory,
    getCategory,
    updateCategory,
    deleteCategory,
    createProduct,
    getProduct,
    listProduct,
    updateProduct,
    deleteProduct,
    createOption,
    createFoodOption,
    updateFoodOption,
    updateProductOption,
    updateProductFoodOption,
    updateOption,
    deleteOption,
    deleteFoodOption,
    listOption,
    listFoodOption,
    listProductFoodOption,
    listProductOption,
    listProductStock,
    updateProductStock,
    createReservation,
    createReservationGroup,
    listReservation,
    updateReservation,
    deleteReservation,
    createAdsBanner,
    listAdsBanner,
    updateAdsBanner,
    deleteAdsBanner,
    createFeed,
    listFeed,
    updateFeed,
    deleteFeed,
    getSelf,
    createShopCoupon,
    createProductCoupon,
    createManyCoupon,
    listShopCoupon,
    listProductCoupon,
    updateCoupon,
    getSelfShop,
    updateSelfShop,
    listOrder,
    getOrder,
    updateOrder,
    createStaff,
    listStaff,
    updateStaff,
    listMember,
    listPospalTicketInRealTime,
    listPospalTicket,
    listPospalBranch,
    listPospalCategory,
    createPospalCategory,
    updatePospalCategory,
    deletePospalCategory,
    listPospalOptionGroup,
    savePospalOptionGroup,
    deletePospalOptionGroup,
    listPospalProduct,
    getPospalProduct,
    createPospalProduct,
    editPospalProduct,
    deletePospalProduct,
    importPospalProduct,
    listPospalMember,
    createPospalMember,
    updatePospalMember,
    importPospalMember,
    emailPospalTicket,
    getPospalUserCategory,
    linkPospalProduct,
    unlinkPospalProduct,
    updateStockTake,
    inactivateReservations,
    getMemberCount,
    getMemberCountByPeriod,
    getMemberCumulativeCountByPeriod,
    getOrderSummary,
    getFoodOrderSummary,
    getOrderSummaryByPeriod,
    getFoodOrderSummaryByPeriod,
    listMostBookmarkedProducts,
    listMostOrderMembers,
    listMostFoodOrderMembers,
    listMostAmountMembers,
    listMostFoodAmountMembers,
    importProduct,
    getShop,
    getShopByInitial,
    getShopByDomainName,
    createNotification,
    listFoodOrder,
    getFoodOrder,
    updateFoodOrder,
    uploadPhoto,
    updateManyProductCategory,
    createDeliveryTimeSlot,
    updateDeliveryTimeSlot,
    deleteDeliveryTimeSlot,
    listDeliveryTimeSlot,
    createFoodSubchoice,
    updateFoodSubchoice,
    listFoodSubchoice,
    deleteFoodSubchoice,
    createProductSubchoice,
    updateProductSubchoice,
    listProductSubchoice,
    deleteProductSubchoice,
    getMemberFoodBehaviorAnalysis,
    getFoodSaleAnalysis,
    groupUpdateProductOption,
    groupUpdateProductFoodOption,
    updateManyOrderStatus,
    deleteCoupon,
    searchProduct,
    getProductStockHistory,
    listProductStockAlert,
    listMemberCredit,
    listScanPayment
}